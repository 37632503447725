.container {
  position: absolute;
  width: 250px;
  height: 40px;
  left: 50%;
  background-color: red;
  transform: translate(-50%, 50px);
  bottom: 0;
  transition: transform 500ms ease-in-out, opacity 500ms ease-in-out;
  opacity: 0;
  text-align: center;
  padding: 10px;
  display: flex;
  align-items: center;
}

.container p {
  width: auto;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.container.toggle {
  transform: translate(-50%, 0px);
  background-color: #00b7bd;
  opacity: 1;
}
