@import url("https://fonts.googleapis.com/css2?family=Jura&display=swap");

.hero {
  width: auto;
  height: 100vh;
  color: white;
  position: relative;
  text-align: center;
  background-color: #1b1b1b;
}

.content {
  min-height: 100vh;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: center;
}

.h1 {
  display: inline-block;
  position: relative;
  white-space: pre-wrap;
  font-size: var(--font-l);
  font-family: var(--font-fam-four);
  transform: translate(-50px, -100px);
}

@media (max-width: 1000px) {
  .h1 {
    transform: translate(20px, -7vh);
    transition: transform 500ms ease;
  }
}

.first {
  font-family: var(--font-fam-five);
  font-size: clamp(1.25rem, 0.8594rem + 1.25vw, 1.5625rem);
  margin-left: 5px;
  text-align: left;
}

.second {
  display: flex;
  display: inline-flex;
  transform: translate(-30px, 10px);
  font-size: clamp(1.875rem, -1.6406rem + 11.25vw, 4.6875rem);
}

.third {
  display: flex;
  display: inline-flex;
  opacity: 0.5;
  font-size: clamp(1.875rem, -1.25rem + 10vw, 4.375rem);
  transform: translateY(-20px);
}

@media (max-width: 750px) {
  .third {
    transform: translateY(-60px);
    transition: transform 500ms ease;
  }
}

.content span {
  filter: var(--ds-three);
}

@media (max-width: 640px) {
  .content span {
    filter: drop-shadow(-5px 5px 3px rgba(0, 0, 0, 0.405));
  }
}

.big-initial {
  width: clamp(4.375rem, 2.8125rem + 5vw, 5.625rem);
  height: auto;
  transform: translate(10px, -8px);
}

/* @media (max-width: 430px) {
  .big-initial {
    width: var(--font-m);
    transform: translate(10px, -15px);
  }
} */

@keyframes rubberBand {
  0% {
    transform: scaleX(1) scaleY(1);
  }

  20% {
    transform: scaleX(1.2) scaleY(0.8);
  }

  40% {
    transform: scaleX(0.8) scaleY(1.2);
  }

  60% {
    color: #70d3e2;
    transform: scaleX(1.1) scaleY(0.9);
  }

  75% {
    transform: scaleX(0.9) scaleY(1.1);
  }

  85% {
    transform: scaleX(1.1) scaleY(1);
  }

  100% {
    transform: scaleX(1) scaleY(1);
  }
}

.stretch {
  display: inline-block;
  text-align: left;
  cursor: default;
}

.stretching {
  animation: rubberBand 500ms alternate ease-out;
}

.popup-btn {
  background-image: linear-gradient(
    to bottom,
    #1a1a1a,
    #151515,
    #101010,
    #0c0c0c,
    #0d0d0d
  );

  width: 110%;
  height: 50px;
  position: absolute;
  bottom: 0;
  visibility: visible;
  opacity: 1;
  transition: opacity 500ms linear;
  box-sizing: border-box;
  margin: 0px -20px 0px -20px;
}

.popup-btn.hidden {
  visibility: hidden;
  opacity: 0;
  transform: translateY(100px);
  transition: all 500ms linear;
}

.svg {
  width: auto;
  height: 50px;
  animation: bounce 2000ms ease-in-out infinite,
    rubberBand 500ms alternate ease-out;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-10px);
  }
}
