.contact-section {
  max-width: 100vh;
  height: 650px;
  position: relative;
  left: 50%;
  transform: translate(-50%, 50px);
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.section-head {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.section-title {
  font: 15px;
  font-family: var(--font-fam-one);
}

.text-zone {
  height: auto;
  width: auto;
  display: flex;
  display: inline-flex;
}

.form-container {
  position: relative;
  width: 100%;
  height: 350px;
}

.form-container form {
  display: block;
  display: inline-block;
  box-sizing: border-box;
}

.join {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.button {
  position: relative;
  width: 100%;
  max-width: 130px;
  height: 55px;
  color: #00f0ff;
  font-size: 18px;
  background-color: transparent;
  border: 2px solid #00f0ff;
  border-radius: 4px;
  cursor: pointer;
  top: 20px;
  background: linear-gradient(
    to left,
    #1b1b1b,
    #1b1b1b 50%,
    #00f0ff 50%,
    #00f0ff
  );
  background-size: 200% 100%;
  background-position: right bottom;
  transition: color 1s ease-out, background 500ms ease-out,
    box-shadow 200ms ease-in;
}

.button:hover {
  color: #1b1b1b;
  background-position: left bottom;
  box-shadow: #00f0ff 0px 2px 4px 0px, #00f0ff 0px 2px 16px 0px;
  transition: color 1s ease-in, background 500ms ease-out,
    box-shadow 200ms ease-in;
}
