.App {
  text-align: center;
}

* {
  margin: 0;
  padding: 0;
}

html {
  overflow-x: hidden;
  overflow-y: scroll;
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  border-radius: 25px;
  background-color: #4d8fa266;
}

body {
  margin: 0;
  width: 100%;
  height: auto;
  background-color: #1b1b1b;
  color: #ffff;
  transition: all 500ms ease-in;
  overflow-x: hidden;
  /* overflow-x: hidden; */
}

@media all and (max-width: 430px) {
  .section-title,
  .divider,
  .skill-content {
    margin-right: auto;
    margin-left: auto;
    text-align: center;
  }
}

ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}

@media (max-width: 480px) {
  body {
    overflow: hidden;
    width: auto;
    height: auto;
  }
}

section {
  padding: 0px 20px 0px 20px;
}
