.aboutsection {
  width: 900px;
  height: 700px;
  position: relative;

  display: block;
  display: inline-block;
}

.aboutcontent {
  position: relative;
  display: flex;
  display: inline-flex;
  max-width: 100%;
  flex-direction: row-reverse;
  gap: 80px;
  justify-content: center;
  align-items: center;
}

@media (max-width: 550px) {
  .aboutcontent {
    flex-direction: column-reverse;
    gap: 0;
  }

  .myInfo {
    visibility: hidden;
  }

  .image {
    visibility: visible;
    width: 100%;
  }
}

@media (min-width: 430px) {
  .aboutsection {
    width: auto;
    height: auto;
    padding: 0px 20px 0px 20px;
  }
}

.myInfo {
  max-width: 200px;
  background-color: #62fff73a;
  border-radius: 20px;
  position: relative;
  box-shadow: rgba(0, 225, 255, 0.19) 0px -5px 10px,
    rgba(255, 0, 234, 0.23) 0px 6px 6px;
  padding: 50px;
}

.image {
  max-width: 200px;
  display: block;
  top: 50px;
  left: 50%;
  border-radius: 100%;
}

@media (min-width: 430px) {
  .image {
    width: 100%;
  }

  .myInfo {
    width: 50%;
    height: 100%;
  }
}

.about-text {
  position: relative;
  padding: 30px 0;
  display: grid;
  grid-gap: 2em;
  max-width: 450px;
}

.about-text p {
  width: 100%;
  display: block;
}

.about-text a {
  text-decoration: none;
  color: var(--primary-color);
}

@media (max-width: 430px) {
  .about-text {
    position: relative;
    justify-content: center;
    margin-bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    max-width: 450px;
  }

  .about-text p {
    text-align: left;
    font-size: 13px;
  }
}

@media (max-width: 430px) {
  .aboutsection {
    width: auto;
    height: auto;
  }

  .aboutcontent {
    align-items: center;
    flex-direction: column-reverse;
  }
}

.aboutsection div:nth-child(3) {
  margin-top: 50px;
  margin-right: 30px;
}
