@import url("https://fonts.googleapis.com/css2?family=Mitr&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200&display=swap");

.home {
  width: auto;
  height: auto;
}
.container {
  position: relative;
  max-width: fit-content;
}

.section-title {
  text-align: left;
  padding-top: 100px;
  font-family: var(--font-fam-two);
  font-size: clamp(1.25rem, 0.4688rem + 2.5vw, 1.875rem);
  filter: var(--ds-three);
}

.section-title::after {
  position: absolute;
  display: inline-block;
  content: "";
  height: 1px;
  width: 18vw;
  transform: translate(10px, 25px);
  background-color: #ffffff80;
}

.end-section {
  position: absolute;
  content: "";
  height: 1px;
  width: 40%;
  background-color: #ffffff80;
  right: 0px;
  margin-right: 20px;
}

@media (max-width: 430px) {
  .container {
    width: auto;
    height: auto;
    left: 25px;
  }
}

.divider {
  display: flex;
  margin: 10px 0;
  width: 0 auto;
  height: 2px;
  background-color: #00b7c7;
}

.center {
  margin-right: auto;
  margin-left: auto;
  text-align: center;
}

.p {
  width: 100%;
  text-align: left;
  font-size: clamp(0.875rem, 0.5625rem + 1vw, 1.125rem);
  font-family: var(--font-fam-three);
  letter-spacing: 1px;
  word-spacing: 1px;
}

.project-title {
  font-family: var(--font-fam-one);
  font-size: 20px;
  filter: var(--ds-three);
}
