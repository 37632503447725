.project-section {
  max-width: 1000px;
  height: auto;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.project-content {
  width: 100%;
  max-width: 1000px;
}

.project-list img {
  height: 150px;
  width: 180px;
  border-radius: 25px;
}

.project-pin {
  position: relative;
  width: 100%;
}

.main-ul {
  width: 100%;
  max-width: 1000px;
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
}

.main-li {
  list-style: none;
  display: list-item;
  margin: 20px 20px 0px 20px;
}

.pin-container {
  position: relative;
  display: flex;
  width: 100%;
  max-width: 1000px;
  align-items: center;
  justify-content: center;
}

.pin-image {
  position: relative;
  border-radius: 25px;
  z-index: 0;
  transition: all 0.3s ease-out;
}

.pin-image:hover {
  z-index: 999;
}
.pin-image:hover .mediaimg {
  transform: scaleY(1.2) rotate(-10deg);
}

.mediaimg {
  margin: 0;
  line-height: 1;
  border: none;
  transition: all 0.3s ease-out;
  filter: var(--ds-one);
  width: 100%;
  max-width: 250px;
}

.image-container {
  width: 100%;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #003539;
  mix-blend-mode: color;
  border-radius: 20px;
  opacity: 1;
  transition: opacity 500ms ease-in-out;
}

.pin-image:hover .overlay {
  opacity: 0;
  transition: opacity 500ms ease-in-out, transform 500ms ease-in-out;
}

.image {
  margin: 0;
  line-height: 1;
  border: none;
  width: 100%;
  max-width: 400px;
  transition: all 500ms ease-out;
  border-radius: 20px;
  box-shadow: var(--bs-four);
}

.description-container {
  text-align: right;
  width: auto;
  max-width: 500px;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 8;
}

.pin-description {
  position: relative;
  background-color: var(--lighter-bg);
  border-radius: 10px;
  padding: 20px;
  max-width: 400px;
  box-shadow: var(--bs-three);
}

.pin-description p {
  font-size: 14px;
  text-align: right;
  width: 100%;
}

.pin-container > div:not(:nth-child(2)) p,
.pin-container > div:not(:nth-child(2)) {
  text-align: left;
}

.main-ul .main-li:nth-of-type(2) .pin-container .pin-image,
.pin-container > div:nth-child(2) {
  transform: translateX(-25px);
}

.main-ul
  .main-li:nth-of-type(2)
  .pin-container
  > div:nth-child(1)
  .pin-description
  p,
.main-ul .main-li:nth-of-type(2) .pin-container > div:nth-child(1) {
  text-align: left;
  transform: translateX(25px);
}

.main-ul .main-li:nth-of-type(2) .pin-container {
  position: relative;
  display: flex;
}

.project-tech-list {
  position: relative;
  display: flex;
  height: auto;
  float: right;
}

.project-tech-list .ul {
  text-align: left;
  list-style: none;
  margin: 0;
  padding: 0;
}

.project-tech-list .ul li {
  display: inline-block;
  font-size: 13px;
  font-family: var(--font-fam-one);
  opacity: 0.4;
  font-style: italic;
  margin: 0px 5px 0px 0px;
}

.project-list {
  width: 100%;
  max-width: 1000px;
  position: relative;
  height: 100%;
  display: block;
}

.project-list .project-ul {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(290px, 0.6fr));
  justify-content: center;
  column-gap: 50px;
  padding: 0;
}

.project-list .project-ul .project-li {
  list-style: none;
  margin: 0;
}

@media (min-width: 1010px) {
  .project-list .project-ul .project-li:nth-child(3n + 1):last-child {
    grid-column-start: 2;
    grid-column-end: 2;
  }
}

@media (min-width: 670px) and (max-width: 1009px) {
  .project-list .project-ul .project-li:nth-child(3n + 1):last-child {
    grid-column-start: 1;
    grid-column-end: 2;
    transform: translateX(25vw);
  }
}

.listtwo {
  float: left;
}

@media (max-width: 600px) {
  .main-li {
    display: block;
  }
}

.end {
  position: absolute;
  bottom: 0;
}

.lazy {
  filter: blur(3px);
  transition: filter 0.5s ease-out;
}

.loaded {
  filter: blur(0);
}
