.navbar {
  width: 100%;
  height: 50px;
  background-color: #1b1b1b;
  display: flex;
  justify-content: space-between;
  transition: all 0.5s ease-in-out;
  padding-top: 20px;
}

.nav-links {
  position: relative;
  display: flex;
  transition: visibility 0.5s cubic-bezier(0.22, 0.68, 0, 1.71);
  font-family: var(--font-fam-five);
}

.ul {
  line-height: 1.5;
  box-sizing: inherit;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  margin: 0px;
  list-style: none;
}

.li {
  display: list-item;
  margin: 20px 20px;
  position: relative;
  counter-increment: item 1;
  font-size: 15px;
}

.li:last-child {
  margin-right: 0;
}

.ul .li a {
  text-decoration: none;
  color: #ccf2fc;
}

.ul .li a:hover {
  color: #00b7c7;
  transition: all 0.3s ease-in-out;
}

.ul .li:nth-child(5) {
  padding: 5px;
  border-radius: 3px;
  cursor: pointer;
}

.ul .li:nth-child(5)::before,
.ul .li:nth-child(5)::after {
  content: "";
  position: absolute;
  width: 100%;
  top: 50%;
  border-top: 2px solid #ccf2fc;
  display: flex;
  transform: translateX(-5px);
}

.ul .li:nth-child(5)::before {
  top: 0;
}

.ul .li:nth-child(5)::after {
  top: 100%;
}

.img {
  width: 50px;
  height: auto;
  -webkit-filter: drop-shadow(12px 12px 7px rgba(0, 225, 255, 0.9));
  filter: drop-shadow(-3px 2px 7px rgba(0, 225, 255, 0.9));
}

@media (max-width: 500px) {
  .nav-links {
    position: fixed;
    backdrop-filter: blur(5px);
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    justify-content: center;
    align-items: center;
    z-index: 99;
    transform: translateX(110%);
    transition: transform 2s cubic-bezier(0.22, 0.68, 0, 1.71);
  }
  .nav-links.toggle-on {
    transform: translateX(0%);
    transition: transform 0.45s cubic-bezier(0.22, 0.68, 0, 1.71);
  }
  .nav-links .ul {
    display: flex;
    display: inline-flex;
    flex-direction: column;
    gap: 10px;
  }

  .nav-links .li {
    margin: 0;
  }

  .menu {
    z-index: 100;
    height: 50px;
    width: 50px;
    margin: 20px 10px 0px 0px;
    position: fixed;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.5s ease-in-out;
    right: 10px;
  }

  .menu_burger::before,
  .menu_burger::after,
  .menu_burger {
    width: 30px;
    height: 3px;
    background: var(--primary-color);
    border-radius: 5px;
    transition: all 0.22s cubic-bezier(0, 1.5, 1, 1.5) 0.11s;
  }

  .menu_burger::before,
  .menu_burger::after {
    content: "";
    position: relative;
    display: flex;
    display: block;
    float: right;
    transition: transform 0.22s cubic-bezier(0, 1.5, 1, 1.5) 0.3s;
  }

  /*** BUTTON ANIMATION ***/
  .menu_burger::before {
    width: 20px;

    transform: translate(0px, -13px);
  }

  .menu_burger::after {
    width: 17px;
    transform: translate(0px, 10px);
  }

  .menu.toggle-on .menu_burger {
    transform: translateX(-50px);
    background: transparent;
  }
  .menu.toggle-on .menu_burger::before {
    width: 30px;
    transform: rotate(45deg) translate(40px, -40px);
    /* transform: rotate(180deg) translate(-50px, -0px); */
  }

  .menu.toggle-on .menu_burger::after {
    width: 30px;
    transform: rotate(-45deg) translate(42px, 37px);
    /* transform: rotate(220deg) translate(-30px, 35px); */
  }

  .ul .li:nth-child(5)::before,
  .ul .li:nth-child(5)::after {
    border: none;
  }
}
