@import url("https://fonts.googleapis.com/css2?family=Caveat&display=swap");

.skill-container {
  height: auto;
  position: relative;
  padding: 20px;
  /* border: 3px solid #ffffff;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
    rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset; */
}

@media (max-width: 965px) {
  .skill-container {
    height: 260px;
  }
}

/* @media (max-width: 655px) {
  .skill-container {
    height: 100%;
  }
} */

.title {
  position: relative;
  font-family: var(--font-fam-one);
  font-size: clamp(0.875rem, 0.5625rem + 1vw, 1.125rem);
  text-align: left;
  margin-left: 40px;
}

.title::before,
.title::after {
  position: relative;
  display: flex;
  font-family: var(--font-fam-six);
  font-size: 14px;
  opacity: 0.6;
  transform: translateX(-20px);
}
.title::before {
  content: "<h1>";
}

.title::after {
  content: "</h1>";
}

.description {
  width: auto;
  text-align: left;
  display: block;
}

.description p {
  display: flex;
  margin: 15px;
}

.description p::before {
  margin-right: 30px;
  content: "";
  width: 2px;
  height: auto;
  border-radius: 25%;
  background-color: rgba(255, 255, 255, 0.5);
}

.description::after,
.description::before {
  font-family: var(--font-fam-six);
  font-size: 14px;
  opacity: 0.6;
}

.description::before {
  content: "<p>";
}

.description::after {
  content: "</p>";
}

.project-container {
  width: 100%;
  border-radius: 20px;
}

.surface {
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  clip-path: polygon(50% 0, 100% 100%, 0 100%);
  width: 100%;
  height: 77px;
  transform: translateY(-231px);
}

.surface::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(transparent, white);
  clip-path: polygon(50% 0, 100% 100%, 0 100%);
}

.image-wrapper {
  display: inline-block;
  position: relative;
  padding: 10px;
  transform: translateX(8px);
}

.image-wrapper::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 119px;
  height: 110px;
  background-color: #00000080;
  z-index: 5;
  transition: opacity 1.5s ease-in;
  opacity: 1;
  transform: translate(40px, 19px);
  border-radius: 2px;
}

.image-wrapper:hover::before {
  opacity: 0;
  transition: opacity 100ms ease-out;
}

.image-wrapper img {
  display: block;
  width: 100%;
  height: auto;
  z-index: 2;
}

.img {
  transition: all 500ms ease-in;
}

.active {
  transform: scale(1.7);
}

.a {
  text-decoration: none;
  color: inherit;
}

.project-details {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 10px;
  background-color: #454545;
  border-radius: 0px 0px 19px 19px;
  box-shadow: var(--bs-one);
  cursor: pointer;
  transition: background-color 500ms ease-in-out;
}

.project-details:hover {
  background-color: #595959;
}

.project-title {
  font-family: "Inter", sans-serif;
  font-size: 20px;
  filter: var(--ds-three);
}

.span {
  position: relative;
  display: flex;
  height: auto;
}

.span .ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: flex-start;
  gap: 15px;
}

.span .ul li {
  font-size: 13px;
  font-family: var(--font-fam-one);
  opacity: 0.4;
  font-style: italic;
}

.p {
  font-size: 14px;
  width: 100%;
  height: 95px;
}

.laptop {
  position: relative;
  height: auto;
  width: 100%;
  margin: 0 auto;
  perspective-origin: 50% 100%;
  perspective: 1000px;
  z-index: 5;
  transform: translate(0px, -30px);
  transition: all 250ms ease-out;
}

.laptop:hover {
  transform: scale(1.8) translate(0px, -35px);
  z-index: 5;
}

@media (max-width: 400px) {
  .laptop:hover {
    transform: scale(1.5) translate(0px, -35px);
  }
}

.laptop_screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 115px;
  min-width: 100px;
  width: calc(80% - 80px);
  margin: 0 auto;
  border: 4px solid #000000;
  border-radius: 15px 15px 0 0;
  transform-origin: center bottom;
  transform: rotateX(-90deg);
  transform-style: preserve-3d;
  transition: all 250ms ease-out;
  overflow: hidden;
  visibility: hidden;
  box-shadow: var(--bs-two);
}

.laptop_screen::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.laptop-open {
  transform: rotateX(0deg);
  visibility: visible;
}

.laptop-base::before {
  position: absolute;
  content: "";
  height: 5px;
  background-color: #5498a4;
  width: 100%;
  border-radius: 10px 10px 0px 0px;
  left: 50%;
  transform: translate(-50%, -5px);
  transition: all 350ms ease-out;
}

.laptop-base {
  position: relative;
  width: 73%;
  height: 1px;
  background-color: #ddff00;
  left: 50%;
  transform: translate(-50%, 5px);
  transition: all 250ms ease-out;
}

.laptop-base::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 10px;
  background-color: #c8c8c8;
  border-radius: 0px 0px 10px 10px;
  left: 50%;
  transform: translate(-50%, 1px);
  box-shadow: var(--bs-base);
}

.open {
  background-color: #c8c8c8;
}

.open::before {
  background-color: #c8c8c8;
}
