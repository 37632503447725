.footer {
  width: 100%;
  height: 15vh;
  color: rgb(255, 255, 255, 0.5);
  font-family: var(--font-fam-one);
  text-align: center;
  font-size: 12px;
  display: flex;
  align-items: center;
}

.footer-content {
  position: relative;
  width: 100%;
  display: flex;
  display: inline-flex;
  flex-direction: column;
  text-align: center;
  margin-top: 20px;
  gap: 10px;
}

.links {
  display: flex;
  display: inline-flex;
  justify-content: center;
}

.links svg {
  width: 20px;
  height: auto;
  color: #fff;
  margin: 0;
  padding: 0;
}

.links ul {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.links ul li {
  padding: 0;
  margin: 0 5px;
}

.links ul li a {
  position: relative;
  display: inline-block;
  text-decoration: none;
  text-decoration-skip-ink: auto;
  transition: all 250ms ease-in-out;
}

.links ul li a:hover {
  transform: translateY(-5px);
}

.links ul li a:hover svg {
  color: #2efce9;
}
