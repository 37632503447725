@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap");

.skill-section {
  max-width: 1050px;
  height: auto;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* .section-title::after {
  width: 50vw;
} */

.skill-content {
  width: 100%;
  max-width: 1050px;
  position: relative;
}

.skill-content ul {
  width: 100%;
  max-width: 1050px;
  position: relative;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
  list-style-type: disc;
  padding: 0;
}

@media (max-width: 340px) {
  .skill-content ul {
    grid-template-columns: none;
  }
}

.skill-content ul li {
  list-style: none;
  display: list-item;
  margin: 0px 0px 10px 0px;
}

@media (max-width: 935px) {
  .skill-content ul li:last-child {
    grid-column: 1 / -1;
  }
}

.inner {
  position: relative;
  width: 105%;
  max-width: 1000px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  justify-content: center;
  padding: 0px;
  margin: 0px;
  gap: 20px;
}

@media (max-width: 1050px) {
  .skill-content {
    transform: translateX(0px);
  }
  .inner {
    width: 100%;
    transform: translateX(0px);
  }
}

@media (max-width: 780px) {
  .inner {
    gap: 50px;
  }
}

@media (max-width: 480px) {
  .inner {
    gap: 50px;
  }
}

@media (max-width: 378px) {
  .inner {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    width: 100%;
  }
}

.expereince-container {
  position: relative;
  box-sizing: border-box;
}

.expereince-content {
  background-color: var(--lighter-bg);
  padding: 20px;
  box-shadow: var(--bs-one);
}

.tech-list-container {
  position: relative;
  width: 100%;
  background-color: var(--lighter-bg);
  margin-top: 10px;
}

.tech-list {
  width: 100%;
  box-shadow: var(--bs-one);
}

.tech-list ul {
  list-style: none;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding: 0px;
  margin: 0px;
  align-items: center;
}

.tech-list ul li {
  font-size: clamp(0.875rem, 0.5625rem + 1vw, 1.125rem);
  font-family: var(--font-fam-one);
  font-style: italic;
  padding: 0px;
  margin: 0px;
}

.linkIcon {
  transition: all 250ms ease-in-out;
}

.linkIcon:hover {
  transform: scale(1.1) translateY(-5px);
  transition: all 250ms ease-in-out;
  fill: #2efce9;
}

.graph {
  position: relative;
  text-align: left;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 28px;
}

.bar-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.bar-container span {
  font-size: 18px;
  font-family: var(--font-fam-one);
}

.bar-parent {
  width: 100%;
  height: 5px;
  border-radius: 5px;
  background-color: #363636;
  box-shadow: var(--bs-three);
}

.bar-child {
  height: 5px;
  border-radius: 5px;
}

.end {
  position: absolute;
  bottom: 0;
  transform: translateY(50px);
}
