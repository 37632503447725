.container {
  position: relative;
  width: 100%;
  display: inline-block;
  text-align: left;
  margin: 0;
  padding: 0;
}

.label {
  position: absolute;
  font-size: clamp(0.625rem, 0.3125rem + 1vw, 0.875rem);
  color: #ffffff80;
  font-family: var(--font-fam-one);
  transform: translate(20px, 25px);
  transition: all 250ms ease-in;
}

.focus {
  font-size: 12px;
  transform: translate(15px, 10px);
  color: #ffffff4d;
}

.textarea,
.Input {
  width: 100%;
  height: 65px;
  font-size: clamp(0.625rem, 0.3125rem + 1vw, 0.875rem);
  border-radius: 10px;
  text-align: left;
  padding: 15px 20px 15px 20px;
  background-color: #303030;
  color: #ffffff;
  box-shadow: var(--bs-three);
  box-sizing: border-box;
  margin-bottom: 5px;
  border: none;
}
.textarea:focus,
.Input:focus {
  outline: none;
  box-shadow: none;
  outline-width: 0;
}

.Input.empty {
  color: rgba(0, 0, 0, 0.3);
  font-weight: 100;
}

.textarea {
  resize: none;
  width: 100%;
  height: 150px;
  padding: 25px 20px 15px 20px;
}
